import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';
import poster1 from '../assets/images/separateImages/MARSKINELIAI IR KUPRINES.jpg';
import SinglePicture from '../components/SinglePicture';


const Clothes = () => (
  <Layout>
    <Helmet
      title="Velgama - Drabužiai"
      meta={[
        { name: 'description', content: 'Reklama ant drabužių' },
        { name: 'keywords', content: '' }, // TODO
      ]}
    />

    <div id="main">
      <Banner h1="Drabužiai" paragraph="Darbo drabužiai, marškinėliai, kepurės" bannerType="clothes-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />
      </section>
      <SinglePicture src={poster1} alt="Marškinėlių plakatas su kainomis" />
    </div>
  </Layout>
);

const galleryQuery = graphql`
query clothesImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-clothes" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default Clothes;
